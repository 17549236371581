@use '../utils/' as *;

.form-flow-wrapper {
  .header-tabs {
    .zinq-tabs-nav {
      padding: 0 24px;
      margin: 0;
      border-bottom: 1px solid var(--border-primary);
      height: 64px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
      background: var(--surface-primary);
    }
  }
  .editor-wrapper {
    height: 100vh;
    background-color: var(--background);
    .left-tabs {
      .zinq-tabs-nav {
        padding: 0;
        .zinq-tabs-nav-wrap {
          .zinq-tabs-nav-list {
            // .zinq-tabs-tab {
            //   &.zinq-tabs-tab-active {
            //     border-radius: 16px;
            //     border: 1px solid var(--neutrals3);
            //     background: var(--neutrals1);
            //     box-shadow:
            //       25px 48px 15px 0px rgba(0, 0, 0, 0),
            //       16px 31px 14px 0px rgba(0, 0, 0, 0.01),
            //       9px 17px 12px 0px rgba(0, 0, 0, 0.05),
            //       4px 8px 9px 0px rgba(0, 0, 0, 0.09),
            //       1px 2px 5px 0px rgba(0, 0, 0, 0.1),
            //       3px 3px 1.9px 0px rgba(255, 255, 255, 0.88) inset,
            //       -3px -3px 5px 0px rgba(0, 0, 0, 0.18) inset;
            //     .zinq-tabs-tab-btn {
            //       color: var(--neutrals10);
            //     }
            //   }
            //   border-radius: 16px;
            //   background: var(--neutrals1, #f3f5f6);
            //   .zinq-tabs-tab-btn {
            //     color: var(--neutrals6);
            //   }
            // }
          }
        }
      }
      .zinq-tabs-content-holder {
        border: 0;
        padding: 0 24px 24px;
        overflow-y: auto;
        height: calc(100vh - 54px);
        max-width: $containerMd;
        margin: 0 auto;
        .zinq-tabs-content {
          overflow-y: auto;
          height: calc(100vh - 105px);
          border: 1px solid var(--neutrals3);
          padding: 24px;
          border-radius: 16px;
          .zinq-tabs-tabpane {
            padding: 0;
            height: 100%;
          }
        }
      }
    }
  }
  .form-flow-left {
    margin: 0;
    // width: 400px;
    .zinq-input {
      padding: 0;
      border: 0;
      width: 100%;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .workflow-section {
    display: table;
    height: calc(100vh - 64px);
    background-color: var(--neutral-00);
    position: relative;
    margin: 0 auto;
    width: 100%;
    .workflow-section-content {
      display: table-cell;
      vertical-align: middle;
      .workflow-list-wrapper {
        padding: 16px;
        border-radius: 16px;
        background-color: var(--surface-primary);
        border: 1px solid var(--border-secondary);
        .workflow-list {
          list-style: disc;
          padding-left: 20px;
          li {
            color: var(--content-secondary);
          }
        }
      }
    }
  }
}

.draggable-form-list-wrapper {
  .draggable-form-list {
    .zinq-list-item {
      border: 0;
      .custom-list-renderer {
        display: flex;
        justify-content: space-between;
        .form-item-count {
          min-width: max-content;
        }
        .form-item-overview {
          display: flex;
          align-items: center;
          gap: 8px;
          background: var(--surface-secondary);
          border-radius: 12px;
          padding: 16px;
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;
          position: relative;
          cursor: pointer;
          &:hover {
            box-shadow:
              0px 17px 7px 0px rgba(0, 0, 0, 0.01),
              0px 2px 6px 0px rgba(0, 0, 0, 0.08);
            border-color: var(--border-secondary);
            .card-controls {
              opacity: 1;
            }
          }
          .card-controls {
            opacity: 0;
            position: absolute;
            top: 16px;
            right: 16px;
            transition: all 0.2s ease-in-out;
          }
          &.new-item {
            animation: blink 1s linear infinite;
            @keyframes blink {
              0% {
                opacity: 1;
                border-color: var(--border-secondary);
              }
              50% {
                opacity: 0.4;
                border-color: var(--border-secondary);
              }
              100% {
                opacity: 1;
                border-color: var(--border-secondary);
              }
            }
          }
        }
        .form-item-card {
          .card-action-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
            .copy-icon {
              margin-bottom: 0;
            }
            .required-setting {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
            }
          }
          .option-list {
            padding: 8px;
            border: 1px solid var(--neutrals1);
            border-radius: 6px;
          }

          // .zinq-row {
          //   .zinq-col {
          //     label {
          //       font-size: 14px;
          //       color: var(--neutrals8);
          //       font-weight: 500;
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.conversational-view-wrapper {
  height: 100vh;
  .form-response {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    .zinq-form {
      .zinq-input-outlined {
        border-bottom: 1px solid var(--neutrals5);
        box-shadow: none;
      }
    }
  }
}
.form-preview-mode-wrapper {
  height: calc(100vh - 80px);
  &.tab-height {
    height: calc(100vh - 144px);
  }
  .widget-preview-wrapper {
    background-color: var(--surface-secondary);
    .widget-controls-wrapper {
      box-shadow:
        24px 68px 27px 0px rgba(0, 0, 0, 0.03),
        8px 39px 23px 0px rgba(0, 0, 0, 0.04),
        4px 17px 17px 0px rgba(0, 0, 0, 0.06),
        2px 4px 8px 0px rgba(0, 0, 0, 0.08);
      height: calc(100vh - 128px);
      display: table;
      width: 100%;
      position: relative;
      overflow: hidden;
      .control-widget {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        .widget-popover-wrapper {
          width: 360px;
          height: 360px;
          position: absolute;
          bottom: 100px;
          right: 24px;
          box-shadow: rgba(25, 25, 25, 0.04) 0px 0px 1px 2px;
          border-radius: 16px;
          animation: fadeIn 0.5s ease forwards;

          @keyframes fadeIn {
            0% {
              opacity: 0;
              visibility: hidden;
            }
            100% {
              opacity: 1;
              visibility: visible;
            }
          }
          .form-preview-mode {
            border-radius: 16px;
          }
        }
        .widget-sidetab-wrapper {
          width: 400px;
          flex: 1 0 auto;
          height: 500px;
          box-shadow: rgba(25, 25, 25, 0.04) 0px 0px 1px 2px;
          border-radius: 16px 0 0 16px;
          transition: all 400ms ease;
          transform: translate(418px, -50%);
          position: fixed;
          right: 18px;

          &.open {
            transform: translate(0, -50%);
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
              visibility: hidden;
            }
            100% {
              opacity: 1;
              visibility: visible;
            }
          }
          .form-preview-mode {
            border-radius: 16px 0 0 16px;
          }
        }
      }
    }
  }
}

.form-preview-mode {
  position: relative;
  padding: 24px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 1;
  box-shadow:
    24px 68px 27px 0px rgba(0, 0, 0, 0.03),
    8px 39px 23px 0px rgba(0, 0, 0, 0.04),
    4px 17px 17px 0px rgba(0, 0, 0, 0.06),
    2px 4px 8px 0px rgba(0, 0, 0, 0.08);
  .preview-bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0px 2000px inset;
    background-position: center;
  }

  .img-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #00000080;
  }

  &.mobile {
    width: 375px;
    margin: 0 auto;
    height: 90%;
    border-radius: 0px 0px 30px 30px;
    max-height: 812px;
    padding: 16px;
  }

  .conversational-form-preview {
    max-height: calc(100vh - 200px);
    width: 100%;
    overflow-y: auto;
    container-type: inline-size;
    container-name: responsive-frame;

    &.voice-form-preview {
      overflow-x: hidden;
    }

    .conversational-form-renderer {
      width: 100%;
      .group-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        padding: 24px;
        border-radius: 16px;
        background: rgba(0, 0, 0, 0.2);
        margin-bottom: 32px;
        backdrop-filter: blur(20px);
        &.light {
          background: rgba(255, 255, 255, 0.2);
        }
        .full-width {
          grid-column: span 2;
          @container responsive-frame (max-width: 480px) {
            grid-column: span 1;
          }
        }
        .zinq-form-item {
          label {
            @include headingH3();
            @container (max-width: 480px) {
              @include bodyBase();
            }
          }
        }
        @include respond-to($screen991) {
          grid-template-columns: repeat(1, 1fr);
        }
        @container responsive-frame (max-width: 480px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      .group-field-title {
        @include headingH2();
        @container responsive-frame (max-width: 480px) {
          @include bodyL();
        }
      }
    }
    // &.mobile {
    //   &.nested-form {
    //     padding: 0 16px 100px;
    //   }
    //   .conversational-form-renderer {
    //     width: 100%;
    //     .group-field-title {
    //       @include bodyL();
    //     }
    //     .group-fields {
    //       display: grid;
    //       grid-template-columns: repeat(1, 1fr);
    //       gap: 40px;
    //       border-radius: 16px;
    //       background: rgba(0, 0, 0, 0.2);
    //       margin-bottom: 32px;
    //       backdrop-filter: blur(20px);
    //       &.light {
    //         background: rgba(255, 255, 255, 0.2);
    //       }
    //       .full-width {
    //         grid-column: span 1;
    //       }
    //       @include respond-to($screen991) {
    //         grid-template-columns: repeat(1, 1fr);
    //       }
    //     }
    //   }
    // }
    .floating-bottom-bar {
      width: 140px;
      height: 5px;
      border-radius: 100px;
      background-color: var(--neutrals11);
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
    .zinq-form {
      .zinq-form-item {
        label {
          @include headingH2();
          @container (max-width: 480px) {
            @include bodyL();
          }
        }
      }
    }
  }
  .classic-form-preview {
    max-height: calc(100vh - 200px);
    width: 100%;
    overflow-y: auto;
    container-type: inline-size;
    container-name: responsive-frame;

    .classic-form-renderer {
      width: 100%;
      .group-field-title {
        @include headingH2();
        @container responsive-frame (max-width: 480px) {
          @include bodyL();
        }
      }
      .group-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        padding: 24px;
        border-radius: 16px;
        background: rgba(0, 0, 0, 0.2);
        margin-bottom: 32px;
        backdrop-filter: blur(20px);
        &.light {
          background: rgba(255, 255, 255, 0.2);
        }
        .full-width {
          grid-column: span 2;
          @container responsive-frame (max-width: 480px) {
            grid-column: span 1;
          }
        }
        .zinq-form-item {
          label {
            @include headingH3();
            @container (max-width: 480px) {
              @include bodyBase();
            }
          }
        }
        @include respond-to($screen991) {
          grid-template-columns: repeat(1, 1fr);
        }
        @container responsive-frame (max-width: 480px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    .form-header {
      border-radius: 16px;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      // animation: fadeInUp 0.5s ease forwards; // commenting for future use
      @include respond-to($screen991) {
        padding: 32px;
      }
      .text-header {
        font-size: 28px;
        text-align: center;
      }
    }
    .zinq-form {
      .zinq-form-item {
        label {
          @include headingH2();
          @container (max-width: 480px) {
            @include bodyL();
            // 18,16
          }
        }
      }
    }
    // &.mobile {
    //   // &.nested-form {
    //   //   padding: 0 16px 100px;
    //   // }
    //   .classic-form-renderer {
    //     width: 100%;
    //     .group-field-title {
    //       @include bodyL();
    //     }
    //     .group-fields {
    //       display: grid;
    //       grid-template-columns: repeat(1, 1fr);
    //       gap: 40px;
    //       border-radius: 16px;
    //       background: rgba(0, 0, 0, 0.2);
    //       margin-bottom: 32px;
    //       backdrop-filter: blur(20px);
    //       &.light {
    //         background: rgba(255, 255, 255, 0.2);
    //       }
    //       .full-width {
    //         grid-column: span 1;
    //       }
    //       @include respond-to($screen991) {
    //         grid-template-columns: repeat(1, 1fr);
    //       }
    //     }
    //   }
    //   .form-header {
    //     padding: 32px;
    //     .text-header {
    //       font-size: 28px;
    //       text-align: center;
    //       @include headingH2();
    //     }
    //   }
    // }
    .floating-bottom-bar {
      width: 140px;
      height: 5px;
      border-radius: 100px;
      background-color: var(--neutrals11);
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.mock-desktop-header {
  background-color: var(--neutral-00);
  height: 48px;
  border-radius: 20px 20px 0px 0px;
  padding: 16px 20px;
  box-shadow:
    24px 68px 27px 0px rgba(0, 0, 0, 0.03),
    8px 39px 23px 0px rgba(0, 0, 0, 0.04),
    4px 17px 17px 0px rgba(0, 0, 0, 0.06),
    2px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.mock-mobile-header {
  background-color: #292929;
  height: 48px;
  border-radius: 30px 30px 0px 0px;
  padding: 16px 20px;
  box-shadow:
    24px 68px 27px 0px rgba(0, 0, 0, 0.03),
    8px 39px 23px 0px rgba(0, 0, 0, 0.04),
    4px 17px 17px 0px rgba(0, 0, 0, 0.06),
    2px 4px 8px 0px rgba(0, 0, 0, 0.08);
  width: 375px;
  margin: 0 auto;
}

.zinq-form-builder-config {
  .background-wrapper {
    box-shadow:
      0px 39px 23px 0px rgba(0, 0, 0, 0.03),
      0px 17px 17px 0px rgba(0, 0, 0, 0.04),
      0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    background-color: var(--surface-primary);
    border-radius: 20px 20px 0px 0px;
    padding: 12px 0;
    position: relative;
    .scroll-wrapper {
      height: calc(100vh - 148px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 0 90px 0;
      &.sharing {
        height: calc(100vh - 108px);
      }
      &.template-settings {
        padding: 0 0 32px 0;
      }
      .form-buttons {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .form-save-buttons {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%, -50%);
        border-top: 1px solid var(--border-primary);
        padding: 16px;
        background: var(--surface-primary);
        width: 100%;
      }
      #add-item-container {
        .zinq-dropdown {
          width: 100%;
          max-width: 300px;
          .zinq-dropdown-menu {
            padding: 16px;
            gap: 6px;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            box-shadow:
              0px 5px 11px 0px rgba(0, 0, 0, 0.1),
              0px 20px 20px 0px rgba(0, 0, 0, 0.09),
              0px 44px 27px 0px rgba(0, 0, 0, 0.05),
              0px 79px 31px 0px rgba(0, 0, 0, 0.01),
              0px 123px 34px 0px rgba(0, 0, 0, 0),
              0px 0px 0px 1px rgba(23, 27, 28, 0.1);
            border: 0;
            background: var(--surface-primary);
            margin-bottom: 8px;
            .zinq-dropdown-menu-item {
              padding: 8px 12px;
              border-radius: 12px;
              font-weight: 500;
              background: var(--surface-secondary);
              border: 1px solid transparent;
              transition: all 0.3s ease-in-out;
              &:hover {
                background-color: var(--surface-tertiary);
              }
              &:first-child {
                background-color: transparent;
                pointer-events: none;
                padding: 0;
                color: var(--content-secondary);
              }
              &.zinq-dropdown-menu-item-disabled {
                .text-description {
                  color: #00000040;
                }
              }
            }
          }
        }
      }
      .form-settings-wrapper {
        .section-divider {
          border-color: var(--border-primary);
          margin: 4px 0;
        }
        .checkbox-description-box {
          .checkbox-box {
            padding: 16px;
            border-radius: 16px;
            border: 1px solid var(--border-primary);
            background: var(--surface-secondary);
            min-height: 100%;
            .zinq-checkbox-wrapper {
              @include bodyBase(500);
              color: var(--content-primary);
            }
          }
        }
        .webhook-info-form {
          .zinq-form-item-explain-error {
            position: relative;
          }
        }
      }
      .zinq-form-item .zinq-form-item-label > label {
        font-weight: 500;
      }
      .scroll-wrapper-header {
        padding: 0 16px 12px;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--border-primary);
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: var(--surface-primary);
      }
      .scroll-wrapper-body {
        padding-inline: 16px;
        .zinq-list-empty-text {
          height: calc(100vh - 238px);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .domain-selection-wrapper {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .zinq-radio-wrapper {
            margin: 0;
            gap: 0px;
            .zinq-radio {
              align-self: self-start;
              margin-top: 4px;
            }
            .zinq-radio + span {
              width: 100%;
              padding: 0;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .draggable-form-list {
    .form-item-card {
      background: var(--surface-secondary);
      padding: 16px;
      border-radius: 12px;
    }
  }
}

.form-selected-item {
  margin-bottom: 30px;
  .selected-item-divider {
    border-color: var(--border-primary);
    margin: 0;
  }
  .card-fields {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px;
    border: 1px solid var(--neutrals1);
    border-radius: 8px;
  }
  .options-list-collapse {
    .zinq-collapse-item {
      .zinq-collapse-header {
        padding: 0;
      }
      .zinq-collapse-content-box,
      .zinq-collapse-content-active {
        padding-inline: 0;
        .zinq-list-item {
          padding-block: 6px;
        }
      }
    }
  }
  .file-type-checkbox-group {
    padding: 10px 12px;
    background: var(--surface-secondary);
    border-radius: 8px;
    border: 1px solid var(--border-primary);
  }
  .input-nested-fields {
    .row-wrapper {
      @include respond-to($screen1080) {
        flex-wrap: wrap;
      }
      .zinq-tag {
        &.zinq-tag-warning {
          border-radius: 16px;
          height: 40px;
          border: 0;
          background: var(--surface-secondary);
        }
      }
    }
  }
}

.page-layout-option-wrapper {
  .box {
    border-radius: 12px;
    border: 2px solid var(--border-primary);
    padding: 6px;
    height: 90px;
    width: 132px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &.active,
    &:hover {
      border-color: var(--surface-inverse-tertiary);
    }
    .page-filled {
      .mock-bar {
        height: 8px;
        width: 76px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-sub-bar {
        height: 8px;
        width: 60px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-button {
        min-height: 8px;
        width: 22px;
        background-color: var(--surface-primary);
        border-radius: 35px;
        border: 2px solid var(--surface-inverse-tertiary);
      }
    }
    .page-left {
      .mock-bar {
        height: 8px;
        width: 56px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-sub-bar {
        height: 8px;
        width: 44px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-button {
        min-height: 8px;
        width: 22px;
        background-color: var(--surface-primary);
        border-radius: 35px;
        border: 2px solid var(--surface-inverse-tertiary);
      }
    }
    .page-top-center {
      .mock-bar {
        height: 8px;
        width: 68px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-sub-bar {
        height: 8px;
        width: 52px;
        background-color: var(--surface-inverse-tertiary);
        border-radius: 50px;
      }
      .mock-button {
        min-height: 8px;
        width: 22px;
        background-color: var(--surface-primary);
        border-radius: 35px;
        border: 2px solid var(--surface-inverse-tertiary);
      }
    }
    &.box-bg {
      background: url('../../assets/images/page-layout.png');
    }
  }
}

.premium-logo {
  filter: drop-shadow(0px 2px 3.4px rgba(0, 0, 0, 0.1));
}

.theme-list-wrapper {
  .card {
    height: 92px;
    width: 132px;
    border-radius: 12px;
    border: 1px solid var(--border-primary);
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 12px;
    background-color: #eaeafe;
    &:hover,
    &.active {
      box-shadow:
        0px 4px 8px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 2px #fff,
        0px 0px 0px 4px var(--content-tertiary);
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.theme-variations-wrapper {
  .card {
    height: 92px;
    width: 132px;
    border-radius: 12px;
    border: 1px solid var(--border-primary);
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 12px;
    background: linear-gradient(140deg, #efe5cb 1.27%, #cbefe9 70.38%);
    display: flex;
    justify-content: center;
    align-items: center;
    .mock-bar {
      border-radius: 8px;
      background: var(--content-primary);
      width: 96px;
      height: 8px;
    }
    .mock-sub-bar {
      border-radius: 8px;
      background: var(--content-primary);
      width: 74px;
      height: 8px;
    }
    .mock-button {
      border-radius: 4px;
      background: var(--content-primary);
      min-width: 62px;
      height: 16px;
      border: 4px solid var(--content-primary);
    }

    &:hover,
    &.active {
      box-shadow:
        0px 4px 8px 0px rgba(0, 0, 0, 0.12),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 2px #fff,
        0px 0px 0px 4px var(--content-tertiary);
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.radio-group-box {
  .zinq-radio-wrapper {
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--border-primary);
    background: var(--surface-secondary);
    padding: 12px;
    min-height: 100%;
    @include bodyBase(500);
    color: var(--content-primary);
    display: flex;
    align-items: center;
    &.zinq-radio-wrapper-disabled {
      color: var(--content-quarternary);
    }
  }
}

.checkbox-group-box {
  .zinq-checkbox-wrapper {
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--border-primary);
    background: var(--surface-secondary);
    padding: 16px;
    @include bodyBase(500);
  }
}

.welcome-page-wrapper {
  padding-block: 8px;
  container-type: inline-size;
  container-name: responsive-container;
  .page-top-center {
    .page-image {
      // max-width: 480px;
      width: 100%;
      object-fit: contain;
      max-height: 360px;
      border-radius: 12px;
    }
    .page-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 52px;
      text-align: center;
      @container responsive-container (max-width: 480px) {
        @include headingH1();
      }
    }
    .page-body {
      max-width: 800px;
      text-align: center;
      @include bodyM();
      @container responsive-container (max-width: 480px) {
        @include bodyBase();
      }
    }
    .right-section {
      align-items: center;
    }
  }
  .page-left {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    @container responsive-container (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .page-image {
        // max-width: 480px;
        width: 100%;
        object-fit: contain;
        max-height: 360px;
        border-radius: 12px;
      }
    }
    .right-section {
      align-items: flex-start;
      @container responsive-container (max-width: 480px) {
        align-items: center;
      }
    }
    .page-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 52px;
      @container responsive-container (max-width: 480px) {
        @include headingH1();
        text-align: center;
      }
    }
    .page-body {
      max-width: 640px;
      @include bodyL();
      @container responsive-container (max-width: 480px) {
        @include bodyBase();
        text-align: center;
      }
    }
  }
}

.form-branding-page {
  .text-response {
    font-size: 42px;
  }
  .branding-box {
    padding: 24px;
    backdrop-filter: blur(16px);
    border: 1px rgba(229, 232, 233, 0.2);
    border-radius: 16px;
    margin-bottom: 100px;
    .text-header {
      margin-top: 0;
      text-align: center;
      min-width: 800px;
      @include respond-to($screen991) {
        min-width: 343px;
      }
    }

    &.dark {
      background: rgba(84, 62, 216, 0.6);
      .text-header {
        color: var(--content-inverse-primary);
      }
    }

    &.light {
      background: rgba(26, 26, 26, 0.1);

      .text-header {
        color: var(--content-primary);
      }
    }
  }
}

.share-card {
  padding: 16px;
  border-radius: 16px;
  background: var(--surface-secondary);
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-in-out;
    box-shadow:
      17px 7px 0px rgba(0, 0, 0, 0.01),
      0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    border-color: var(--border-secondary);
  }
}

.knowledge-card {
  padding: 16px;
  border-radius: 16px;
  background: var(--surface-secondary);
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-in-out;
    box-shadow:
      17px 7px 0px rgba(0, 0, 0, 0.01),
      0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    border-color: var(--border-secondary);
  }
}

.task-card {
  padding: 16px;
  border-radius: 16px;
  background: var(--surface-secondary);
  border: 1px solid transparent;
  // cursor: pointer;
  // &:hover {
  //   transition: all 0.3s ease-in-out;
  //   box-shadow:
  //     17px 7px 0px rgba(0, 0, 0, 0.01),
  //     0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  //   border-color: var(--border-secondary);
  // }
}

.edit-share-form {
  .dimension-input-wrapper {
    position: relative;
    .unit-selector {
      position: absolute;
      top: 4px;
      right: 6px;
      width: max-content;
    }
  }
  .embed-control-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    width: 100%;
    .full-width {
      grid-column: 1 / -1;
    }
  }
}

.edit-template-form {
  .tags-list-item {
    .zinq-form-item-control-input {
      .zinq-form-item-control-input-content {
        .zinq-select {
          .zinq-select-selection-overflow-item {
            .zinq-select-selection-item {
              border-radius: 14px;
            }
          }
        }
      }
    }
  }
}

.embed-type-grid {
  display: flex;
  gap: 12px;
  width: 100%;
  border-radius: 16px;
  padding: 4px;
  flex-wrap: wrap;

  .embed-type-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background: var(--surface-secondary);
    border-radius: 12px;
    transition: all 0.3s ease;
    width: 100px;
    gap: 8px;
    cursor: pointer;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover,
    &.active {
      background: var(--surface-primary);
      box-shadow:
        0px 4px 4px 0px rgba(0, 0, 0, 0.08),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 1px rgba(32, 32, 32, 0.06);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.common-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--neutral-00);
  opacity: 0.5;
  pointer-events: auto;
}
