@use '../utils' as *;
.result-container {
  max-width: 932px;
  width: 100%;
  margin: auto;
  padding: 24px 16px;
}
.result-segments-wrapper {
  max-width: 788px;
  margin: 12px auto;
}
.editor-wrapper.result-page {
  height: 100vh;
  overflow: auto;
  padding-top: 64px;
}
.editor-wrapper {
  &.workflow-tab {
    height: calc(100vh - 64px);
    overflow: auto;
  }
}
.overview-result {
  display: flex;
  background: var(--neutrals);
  border-radius: 20px;
  box-shadow:
    0px 0px 0px 1px #2020200f,
    0px 1px 2px 0px #0000001a,
    0px 4px 4px 0px #00000014,
    0px 10px 6px 0px #0000000d,
    0px 17px 7px 0px #00000003;

  .item {
    flex: 1;
    padding-top: 40px;
    padding-bottom: 32px;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid var(--border-primary);
    }
  }
}
.summary-wrapper {
  background: var(--surface-primary);
  padding: 24px;
  box-shadow: 0px 0px 0px 1px #2020200f;
  border-radius: 20px;
  margin-bottom: 24px;
  h4 {
    margin-bottom: 12px;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
  }
  .zinq-card-type-inner {
    .zinq-card-head {
      padding: 0 12px;
      min-height: 36px;
      @include bodyS;
      color: var(--content-secondary);
    }
    .zinq-card-body {
      padding: 12px;
    }
  }
  .photo-list {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      gap: 8px;
      padding: 8px;
      flex-direction: column;

      .name {
        font-weight: 500;
      }

      .photo-name {
        display: flex;
        align-items: center;
        gap: 2px;
      }

      .download {
        cursor: pointer;
      }

      svg {
        vertical-align: middle;
      }
    }
  }
  .graph-wrapper {
    width: 100%;
    height: 310px;
  }
}
.feedback-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .feedback-item {
    background: var(--neutral-02);
    padding: 16px;
    border-radius: 12px;
    .fb-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .fb-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }
}
.fb-status {
  display: inline-block;
  background: #e5e8e9;
  color: #343b3f;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  &.positive {
    background: #b7f0ce;
    color: #00994a;
  }
  &.negative {
    background: #f9d2d9;
    color: #e50013;
  }
}
.participant-filter-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  gap: 24px;
  margin-bottom: 12px;
}
.participant-tbl {
  .zinq-table-footer {
    background: var(--background);
    border-radius: 0;
    border: 0;
  }
  .zinq-table-summary {
    .summary-wrapper {
      td {
        padding: 16px 0px;
        .summary-button-wrapper {
          width: 98vw;
          position: sticky;
          left: 0;
        }
      }
    }
  }
  .zinq-table-wrapper .zinq-table-thead > tr > th {
    padding: 12px;
    background: var(--neutral-03);
    color: var(--neutral-07);
    @include bodyS($weight: 500);
    vertical-align: top;
    border-inline-end: 1px solid var(--neutral-04);
    width: 250px;
  }
  .zinq-table-wrapper
    .zinq-table-container
    table
    > thead
    > tr:first-child
    > *:first-child,
  .zinq-table-wrapper
    .zinq-table-container
    table
    > thead
    > tr:first-child
    > *:last-child,
  .zinq-table-wrapper .zinq-table .zinq-table-header {
    border-radius: 0;
  }
  .zinq-table-wrapper .zinq-table-thead > tr > th {
    border-block: 1px solid var(--neutral-04);
  }
  .zinq-table-wrapper .zinq-table-tbody > tr > td,
  .zinq-table-wrapper .zinq-table-thead > tr > th {
    border-bottom: 1px solid var(--neutral-04);
  }
  .zinq-table-wrapper
    .zinq-table.zinq-table-bordered
    > .zinq-table-container
    > .zinq-table-content
    > table
    > tbody
    > tr
    > td,
  .zinq-table-wrapper
    .zinq-table.zinq-table-bordered
    > .zinq-table-container
    > .zinq-table-content
    > table
    > tbody
    > tr
    > .zinq-table-cell-fix-right-first::after,
  .zinq-table-wrapper
    .zinq-table.zinq-table-bordered
    > .zinq-table-container
    > .zinq-table-content
    > table
    > thead
    > tr
    > .zinq-table-cell-fix-right-first::after,
  .zinq-table-wrapper
    .zinq-table.zinq-table-bordered
    > .zinq-table-container
    > .zinq-table-header
    > table
    > thead
    > tr
    > th,
  .zinq-table-wrapper
    .zinq-table.zinq-table-bordered
    > .zinq-table-container
    > .zinq-table-body
    > table
    > tbody
    > tr
    > td {
    vertical-align: top;
    border-inline-end: 1px solid var(--neutral-04);
  }
  .download-btn {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    background: var(--neutral-02);
    border-radius: 8px;
    white-space: normal;
    svg {
      flex-shrink: 0;
      &:first-child {
        margin-right: 2px;
      }
      &:last-child {
        margin-left: 4px;
      }
    }
  }
}
.cs-button-group {
}
.transcript-modal {
  &.zinq-modal {
    .zinq-modal-content {
      padding: 16px;
    }
    .zinq-modal-body {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}
